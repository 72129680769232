import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Northeastern University",
    years: "2006",
    content:
      "Graduated Summa Cum Laude as a Dual Major BS in Visual Arts with a concentration in Animation and Multimedia Studies.",
  },
];

const libertyConceptsData = [
  {
    id: 1,
    title: "Full Stack Developer",
    years: "July 2017 – Present",
    content: "Designed, built, and maintained  CI/CD integrations.  Served as the lead developer on numerous WordPress, Drupal, and Laravel projects.  Integrated with third party APIs such as Stripe, Google Maps, Constant Contact, and Twitter.  Oversaw the maintenance of 100+ websites.",
  },
  {
    id: 2,
    title: "Vice President of Operations",
    years: "October 2014 – July 2017",
    content: "Managed a team of 5 full time developers.  Lead project manager for the GiveGreen contribution bundling website, which raised more than $15M between 2015 - 2017.  Standardized an employee feedback and review process.  Worked with project stakeholders to gather requirements create and maintain project plans.  Continued self directed learning on the LAMP stack.",
  },
  {
    id: 3,
    title: "Director of Client Services",
    years: "June 2011 – October 2014",
    content: "Managed a master project schedule consisting of 5-10 active development projets and 60+ maintenance projects.  Led the interviewing, hiring, and onboarding of new team members.  Taught myself HTML / CSS/ JS so I could more atively contribute and assist on development projects.",
  },
  {
    id: 4,
    title: "Lead Project Manager",
    years: "October 2007 – May 2011",
    content: "Worked closely with the CTO to map out and implement infrastructure and tooling upgrades.  Prioritized and assigned work to the design and development teams.",
  },
];


const metaversalStudiosData = [
  {
    id: 1,
    title: "Director of Creative Services",
    years: "June 2006 – August 2007",
    content: "",
  },
  {
    id: 2,
    title: "Game Designer",
    years: "August 2004 – May 2006",
    content: "",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />

        <div className="row">
          <div className="col-md-8">
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              <h2>Liberty Concepts (Boston)</h2>
              {libertyConceptsData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-4">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
            </div>
          </div>
          <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        </div>

        <div className="spacer" data-height="30"></div>

        <div className="row">
          <div className="col-md-8">
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              <h2>Metaversal Studios (Boston)</h2>
              {metaversalStudiosData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Experiences;
