import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    userName: "Joseph Porcelli",
    subtitle: "Global Public Agency Lead at Nextdoor",
    review:
      "Gabe helped us pull off the impossible - time and time again. When he said it would get done - it would get done. He steered us away from trouble (many times) and saved us from what would have been embarrassing mistakes.",
  },
  {
    id: 2,
    userName: "Tyler Dashner",
    subtitle: "",
    review:
      "I can personally attest to his excellent performance as the project manager for our campaign website. Due to the hectic nature of campaigns, Gabe went out of his way to accommodate our needs and schedule. He's extremely professional and provides excellent customer service.",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="Clients & Review" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
