import React from "react";

function Logo() {
  return (
    <div className="site-logo">
      GC
    </div>
  );
}

export default Logo;
