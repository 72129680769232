import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "Drupal",
  },
  {
    id: 3,
    text: "Laravel",
  },
  {
    id: 4,
    text: "WordPress",
  },
];

const allData = [
  {
    id: 1,
    title: "WE GOT THIS: Cancer thriver registry system",
    category: "Laravel",
    details: [
        "Stipe Connect API integration",
        "Social login integration",
    ],
    image: "/images/works/we-got-this.png",
    link: "https://wegotthis.org",
  },
  {
    id: 2,
    title: "UMass Amherst: 2021 Commencement website",
    category: "Drupal",
    details: [],
    image: "/images/works/umass.png",
    link: "https://www.umass.edu/commencement2021/",
  },
  {
    id: 3,
    title: "Market Basket",
    category: "Drupal",
    details: [],
    image: "/images/works/market-basket.png",
    link: "https://shopmarketbasket.com",
  },
  {
    id: 4,
    title: "Internews.org",
    category: "WordPress",
    details: [
      "Drupal to WordPress migration",
      "React.js",
      "WordPress multisite"
    ],
    image: "/images/works/internews.png",
    link: "https://internews.org",
  },
  {
    id: 5,
    title: "Archdiocese of Boston",
    category: "Drupal",
    details: [
      "Acquia Site Factory",
      "Acquia Content Hub",
      "Stripe API integration",
    ],
    image: "/images/works/boston-catholic.png",
    link: "https://www.bostoncatholic.org",
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category.toLowerCase() === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category.toLowerCase() === activeFilter.toLowerCase() && data.id <= tempCount
          )
        );
      }
    }
  };

  console.log()
  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Recent Works" />
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                  <button
                    onClick={handleChange}
                    className={
                      filter.text.toLowerCase() === activeFilter
                        ? "text-capitalize current"
                        : "text-capitalize"
                    }
                  >
                    {filter.text}
                  </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
        {
          getAllItems.length > visibleItems.length &&
          <button
            className="btn btn-default"
            onClick={handleLoadMore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        }
        </div>
      </div>
    </section>
  );
}

export default Works;
