import React from "react";

const brandlogoData = [
  {
    id: 1,
    image: "/images/logos/logo-lcvaf.png",
    url: "https://www.givegreen.com",
    altText: "Logo for the League of Conservation Voters Action Fund",
  },
  {
    id: 2,
    image: "/images/logos/logo-internews.svg",
    url: "https://internews.org",
    altText: "Logo for Internews",
  },
  {
    id: 3,
    image: "/images/logos/logo-massbio.png",
    url: "https://massbio.org",
    altText: "Logo for the Massachusetts Biotechnology Council",
  },
  {
    id: 4,
    image: "/images/logos/logo-naca.svg",
    url: "https://naca.com",
    altText: "Logo for NACA",
  },
  {
    id: 5,
    image: "/images/logos/logo-boston-catholic.png",
    url: "https://bostoncatholic.org",
    altText: "Logo for the Archdiocese of Boston",
  },
  {
    id: 6,
    image: "/images/logos/logo-foe.png",
    url: "https://foe.org",
    altText: "Logo for the Friends of the Earth",
  },
  {
    id: 7,
    image: "/images/logos/logo-eci.png",
    url: "https://easterncongo.org",
    altText: "Logo for the Eastern Congo Initiative",
  },
  {
    id: 8,
    image: "/images/logos/logo-royal-health.svg",
    url: "https://www.royalhealthgroup.com",
    altText: "Logo for Royal Health Group",
  },
  {
    id: 9,
    image: "/images/logos/logo-we-got-this.svg",
    url: "https://wegotthis.org",
    altText: "Logo for WeGotThis.org",
  },
];

function Brandlogos() {
  return (
    <div id="branglogos">
      <div className="spacer" data-height="60"></div>
      <div className="container">
        <div className="row">
          {brandlogoData.map((brandlogo) => (
            <div className="col-md-3 col-6" key={brandlogo.id}>
              <div className="client-item shadow-dark">
                <div className="inner">
                  <a href={brandlogo.url} target="_blank" rel="noopener noreferrer">
                    <img alt={brandlogo.altText} src={brandlogo.image} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Brandlogos;
